.carousel-container {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 80vw;
	max-width: 500px;
}

.carousel-images {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	width: 80%;
	position: relative;
}

.carousel-image {
	flex: 0 0 20%;
	opacity: 0.5;
	transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
	position: absolute;
	width: 70%;
	max-width: 400px;
	height: auto;
}

.carousel-image.left {
	transform: translateX(-100%);
	left: 0;
}

.carousel-image.right {
	transform: translateX(100%);
	right: 0;
}

.carousel-image.active {
	opacity: 1;
	transform: translateX(0);
	position: relative;
	z-index: 1;
}

.carousel-button {
	background-color: #24ab77;
	color: white;
	font-size: 1.25rem;

	border: none;
	padding: 10px 20px;
	border-top-left-radius: 15px;
	border-bottom-right-radius: 15px;

	cursor: pointer;
	z-index: 2;
}

.carousel-button.prev {
	position: absolute;
	left: 0;
}

.carousel-button.next {
	position: absolute;
	right: 0;
}
